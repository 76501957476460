console.log('--- TYPES FILE LOADING ---');
import * as consts from '@/consts';
import moment from 'moment';

import { Enumify } from 'enumify';

export class DataBaseObject {
  id: number;

  create_date_time: string;
  change_date_time: string;
}

export class RightQueueItem {
  modelName: string;
  data: object;
}

export class DashboardEntity {
  id: number;
  number: string;
  name?: string;

  get getNumber() {
    return this.number;
  }
  set getNumber(value: string) {
    this.number = value;
  }
  get getDONEActionStatus(): Enumify {
    return null;
  }
  get getStatus() {
    return null;
  }
  set statusId(id: number) {
    console.log('');
  }
  update() {
    console.log('');
  }
}

export class Address {
  id: number;
  city?: string;
  street_and_house_no?: string;
  postal_code?: string;
  correspond?: string;
  create_date_time?: string;
  changed_date_time?: string;

  constructor() {
    this.id = 0;
    this.city = '';
    this.street_and_house_no = '';
    this.postal_code = '';
    this.correspond = '';
  }
}

export class Client {
  id: number;
  name?: string;
  short_name?: string;
  code?: string;
  address_id?: number;
  address?: Address;
  nip?: string;
  active?: boolean;
  correspond_address?: string;

  contact_person_name?: string;
  contact_email?: string;
  contact_phone?: string;

  cplaces?: ConstructionPlace[];

  contract?: string;

  create_date_time?: string;
  changed_date_time?: string;

  constructor() {
    this.id = 0;
    this.name = '';
    this.code = '';
    this.cplaces = [];
    this.short_name = '';
    this.address_id = -1;
    this.address = new Address();
    this.active = true;
    this.contact_email = '';
    this.contact_person_name = '';
    this.contact_phone = '';
    this.contract = '';
  }

  load() {
    this.address = null; //dictModule.addressById(this.address_id);
  }

  import?(obj: object) {
    Object.assign(this, obj);
  }
}

export class ConstructionPlace {
  id: number;
  name: string;
  short_name: string;
  symbol?: string;
  active?: boolean;
  client_id: number;

  constructor() {
    this.id = 0;
    this.name = '';
    this.short_name = '';
    this.symbol = '';
    this.active = true;
  }
}

export class Localization {
  id: number;
  name: string;
  code?: string;
}

export class UserRight {
  id: number;
  name: string;
  view_group?: string;
  symbol: string;
  description?: string;
}

export class UserAuth {
  id: number;
  name: string; // dict.value
  type?: number; // dict.type
}

export class UsersRight {
  id: number;
  right_id: number;
  right: UserRight;
  user_id: number;
  user: User;
}

export class UserSubmit {
  email: string;
  password: string;
}

export class UserGroup {
  id: number;
  name: string;
  type: number;
  index: number;
  symbol: string;
  group_ids: number[];
  right_ids: number[];
  rights?: UserRight[];
  right_symbols?: string[];
  description: string;

  load() {
    this.rights = [];
    this.right_ids.forEach(right_id => {
      //const r = usersModule.getRightById(right_id);
      //this.rights.push(r);
    });
  }
}

export class UserData {
  id: number;
  initials: string;
  positions: string;
  module: string;
  status_id: number;
  created_date_time?: string;
  changed_date_time?: string;
  user_id?: number;
  phone: string;
  calendar_index?: number;
}

export class User extends DashboardEntity {
  email: string;
  firstname: string;
  lastname: string;
  position?: string;
  token?: string;
  is_logged_in: boolean;
  created_date_time?: string;
  changed_date_time?: string;
  module?: number;
  initials: string;
  role_id?: number;
  group_ids?: number[];
  groups?: UserGroup[];
  right_ids?: number[];
  rights?: UserRight[];
  status_id?: number;
  user_data?: UserData;
  sign_image?: File;
  sign_image_id?: number;

  sign_image_guid: string;

  rights_to_delete: Array<RightQueueItem>;
  new_rights: Array<RightQueueItem>;

  public get fullName() {
    return this.firstname + ' ' + this.lastname;
  }
  get getNumber() {
    return this.fullName;
  }

  async update() {
    //await usersModule.updateUser(this);
  }

  constructor() {
    super();
    this.id = 0;
    this.email = '';
    this.firstname = '';
    this.lastname = '';
    this.token = '';
    this.is_logged_in = false;
    this.group_ids = [];
    this.groups = [];
    this.right_ids = [];
    this.rights = [];
    this.role_id = -1;
  }

  load(copy = true) {
    this.rights = [];
    this.groups = [];
  }
  hasRight(rightToCheck: UserRight) {
    return true;
  }
}
export class UserResponse {
  user: User;
}
export class Profile {
  user?: User;
}

export class BreadcrumbItem {
  title: string;
  name?: string;
  link?: string | object;
  view?: AppView;
  class?: string;
}

export class Breadcrumb {
  routePrefix: string;
  items: BreadcrumbItem[];
}

export interface AppView {
  broadcrumb?: Breadcrumb;
}

export class File {
  id?: number;
  path?: string;
  related_model?: string;
  related_id?: number;
  create_date_time?: string;
  changed_date_time?: string;
  name?: string;
  guid?: string; // id usera ktory wgral plik
}

export class FilePart {
  id?: number;
  path?: string;
  related_model?: string;
  related_id?: number;
  create_date_time?: string;
  changed_date_time?: string;
  name?: string;
  guid?: string; // id usera ktory wgral plik
}

export class Dict {
  id: number;
  type: number;
  subtype?: number;
  value: string;
  symbol?: string;
  active: boolean;
  value2?: string;
  related_id?: number;
  parent_id?: number;
  description?: string;
  create_date_time: string;
  changed_date_time: string;
}

export class DictToSave {
  active: boolean;
  type: number;
  symbol?: string;
  value: string;
  value2?: string;
}

export class Activity {
  name: string;
  type: number;
  subtype: number;
  symbol?: string;
  user_id: number;
  value_int?: number;
  value_str?: string;
}
