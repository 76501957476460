





























































































import {Component, Emit, Prop, Vue} from "vue-property-decorator";
import {required, minLength, sameAs, email} from 'vuelidate/lib/validators'
import { mixins } from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import { VueRecaptcha } from "vue-recaptcha";

@Component({
  mixins: [validationMixin],
  validations: {
    contactForm: {
      name: {
        required,
      },
      email: {
        required,
        email
      },
      message: {
        required,
        minLength: minLength(6)
      },
      checked: {
        required
      },
    },
  },
  components: {
    VueRecaptcha,
  }
})

export default class ContactForm extends mixins(Vue, validationMixin){
  @Prop({ type: String, required: false, default: "" }) title;
  @Prop({ type: String, required: false, default: "" }) text;
  @Prop({ type: String, required: false, default: "" }) destinationEmail;
  @Prop({ type: String, required: false, default: "" }) phoneNumber;

  form = {
    robot: false,
  }
  contactForm = {
    name: '',
    email: '',
    message: '',
    checked: '',
  }
  submitStatus = ''
  contactCheckboxStatus = null;
  checkboxCheck(event){
    if (event) {
      this.contactCheckboxStatus = true;
      return true;
    }else{
      this.contactCheckboxStatus = false;
      return false;
    }
  }

  @Emit("contact-form-validated")
  contactSubmit(){
    if(this.checkboxCheck(this.contactForm.checked) == true){
      this.$v.$touch()
      if (this.$v.$invalid) {
        return false;
      } else {
        return this.contactForm;
      }
    }else{
      return false;
    }
  }
  submit(){
    if(this.form.robot){
      console.log('robot');
    }
  }
  onVerify(resposne){
    if(resposne){
      this.form.robot = true;
    }
  }

}
