




















































































































import { Component, Vue } from 'vue-property-decorator';
import ContactForm from "@/components/spec/ContactForm.vue";
import LanguageSwitcherElement from "@/components/dummy/LanguageSwitcherElement.vue";
import * as CMS from "@/store/cms";

@Component({
  components: {
    ContactForm,
    LanguageSwitcherElement
  }
})
export default class Footer extends Vue {

  get innerWidth() {
    return window.innerWidth;
  }

  get innerHeight() {
    return window.innerHeight;
  }
  options;
  hasOptions = false;
  async getOptionsFromCMS() {
    const response = await CMS.getOptionsFromCMS();
    if(typeof response === "object"){
      this.options = (response);
      this.hasOptions = true;
    }
  }
  currentRouteName() {
    return this.$route.name;
  }
  created() {
    this.getOptionsFromCMS();
  }
}
