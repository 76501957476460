


























































































































































































































































































































































import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
import ActiveFilters from "@/components/dummy/ActiveFilters.vue";
import DataListInput from "@/components/dummy/DataListInput.vue";
import * as API from "@/store/api";
import * as consts from "@/consts";
import FormSelect from "@/components/dummy/FormSelect.vue";
import ActiveFilter from "@/components/dummy/ActiveFilter.vue";
@Component({
  components: {
    ActiveFilter,
    FormSelect,
    DataListInput,
    ActiveFilters
  }
})
export default class AuctionSearch extends Vue{
  @Prop({ type: String, required: false, default: 'copart' }) type;

  private routeQuery = JSON.stringify(this.$route.query);

  private carSearchForm = consts.carSearchForm;
  private vehicle_typeLabel = 'TYP';
  private makeLabel = 'MARKA POJAZDU';
  private modelLabel = 'MODEL POJAZDU';
  private year__gteLabel = 'ROCZNIK OD';
  private year__lteLabel = 'ROCZNIK DO';
  private odometer__gteLabel = 'PRZEBIEG OD (mi)';
  private odometer__lteLabel = 'PRZEBIEG DO (mi)';
  private cylinders__gteLabel = 'LICZBA CYLINDRÓW OD';
  private cylinders__lteLabel = 'LICZBA CYLINDRÓW DO';
  private fuel_typeLabel = 'TYP PALIWA';
  private driveLabel = 'RODZAJ NAPĘDU';
  private price__gteLabel = 'CENA OD ($)';
  private price__lteLabel = 'CENA DO ($)';
  private sale_title_typeLabel = 'TYP DOKUMENTU SPRZEDAŻY';
  private sale_statusLabel = 'TYP SPRZEDAŻY';

  private fuel_typeList = [];

  private vehicle_typeList = [];
  private makeList = [];
  private model_detailList = [];
  private driveList = [];
  private sale_title_typeList = [];
  private sale_statusList = [];

  private modelList = [];
  private driveline_typeList = [];
  private seller_typeList = [];
  private titleList = [];

  private tmpSelectedEngineList = [];
  private engineTypesList = [];

  private filters = {};

  private isDefaultSet = false;

  async goToAuctionsWithData(event) {
    event.preventDefault()
    window.scrollTo(0, 0);
    const routerQueryJSON = {};
    for (const [key, value] of Object.entries(this.carSearchForm)) {
      if (value.length) {
        routerQueryJSON[key] = value;
      }
    }
    if (this.type === 'copart') {
      await this.$router.push({
        name: 'auctionsCopart',
        query: routerQueryJSON
      });
    } else {
      await this.$router.push({
        name: 'auctionsIaai',
        query: routerQueryJSON
      });
    }
    this.routeQuery = JSON.stringify(this.$route.query);

    this.updateAuctions();
  }

  async getFilteredDataToFields(fieldName = null){
    if(fieldName !== null && fieldName.length){
      if(this.type === 'copart'){
        switch (fieldName){
          case 'vehicle_type':
            this.makeList = await this.getDataFromCopartDictFiltered('make', this.filters);
            this.model_detailList = await this.getDataFromCopartDictFiltered('model_detail', this.filters);
            this.fuel_typeList = await this.getDataFromCopartDictFiltered('fuel_type', this.filters);
            this.driveList = await this.getDataFromCopartDictFiltered('drive', this.filters);
            this.engineTypesList = await this.getDataFromCopartDictFiltered('engine', this.filters);
            break;
          case 'make':
            this.model_detailList = await this.getDataFromCopartDictFiltered('model_detail', this.filters);
            this.vehicle_typeList = await this.getDataFromCopartDictFiltered('vehicle_type', this.filters);
            this.fuel_typeList = await this.getDataFromCopartDictFiltered('fuel_type', this.filters);
            this.driveList = await this.getDataFromCopartDictFiltered('drive', this.filters);
            this.sale_title_typeList = await this.getDataFromCopartDictFiltered('sale_title_type', this.filters);
            this.engineTypesList = await this.getDataFromCopartDictFiltered('engine', this.filters);
            break;
          case 'model_detail':
            this.vehicle_typeList = await this.getDataFromCopartDictFiltered('vehicle_type', this.filters);
            this.makeList = await this.getDataFromCopartDictFiltered('make', this.filters);
            this.fuel_typeList = await this.getDataFromCopartDictFiltered('fuel_type', this.filters);
            this.driveList = await this.getDataFromCopartDictFiltered('drive', this.filters);
            this.engineTypesList = await this.getDataFromCopartDictFiltered('engine', this.filters);
            break;
          case 'sale_title_type':
            this.model_detailList = await this.getDataFromCopartDictFiltered('model_detail', this.filters);
            this.vehicle_typeList = await this.getDataFromCopartDictFiltered('vehicle_type', this.filters);
            this.makeList = await this.getDataFromCopartDictFiltered('make', this.filters);
            this.fuel_typeList = await this.getDataFromCopartDictFiltered('fuel_type', this.filters);
            this.driveList = await this.getDataFromCopartDictFiltered('drive', this.filters);
            this.engineTypesList = await this.getDataFromCopartDictFiltered('engine', this.filters);
            break;
          case 'sale_status':
            this.model_detailList = await this.getDataFromCopartDictFiltered('model_detail', this.filters);
            this.vehicle_typeList = await this.getDataFromCopartDictFiltered('vehicle_type', this.filters);
            this.makeList = await this.getDataFromCopartDictFiltered('make', this.filters);
            this.fuel_typeList = await this.getDataFromCopartDictFiltered('fuel_type', this.filters);
            this.driveList = await this.getDataFromCopartDictFiltered('drive', this.filters);
            this.engineTypesList = await this.getDataFromCopartDictFiltered('engine', this.filters);
            break;
          case 'fuel_type':
            this.vehicle_typeList = await this.getDataFromCopartDictFiltered('vehicle_type', this.filters);
            this.model_detailList = await this.getDataFromCopartDictFiltered('model_detail', this.filters);
            this.makeList = await this.getDataFromCopartDictFiltered('make', this.filters);
            this.driveList = await this.getDataFromCopartDictFiltered('drive', this.filters);
            this.engineTypesList = await this.getDataFromCopartDictFiltered('engine', this.filters);
            break;
        }
      }else{
        switch (fieldName){
          case 'make':
            this.modelList = await this.getDataFromIaaIDictFiltered('model', this.filters);
            this.driveline_typeList = await this.getDataFromIaaIDictFiltered('driveline_type', this.filters);
            this.seller_typeList = await this.getDataFromIaaIDictFiltered('seller_type', this.filters);
            this.titleList = await this.getDataFromIaaIDictFiltered('title', this.filters);
            this.engineTypesList = await this.getDataFromIaaIDictFiltered('engine', this.filters);
            break;
          case 'model':
            this.makeList = await this.getDataFromIaaIDictFiltered('make', this.filters);
            this.fuel_typeList = await this.getDataFromIaaIDictFiltered('fuel_type', this.filters);
            this.driveline_typeList = await this.getDataFromIaaIDictFiltered('driveline_type', this.filters);
            this.seller_typeList = await this.getDataFromIaaIDictFiltered('seller_type', this.filters);
            this.titleList = await this.getDataFromIaaIDictFiltered('title', this.filters);
            this.engineTypesList = await this.getDataFromIaaIDictFiltered('engine', this.filters);
            break;
          case 'fuel_type':
            this.makeList = await this.getDataFromIaaIDictFiltered('make', this.filters);
            this.modelList = await this.getDataFromIaaIDictFiltered('model', this.filters);
            this.driveline_typeList = await this.getDataFromIaaIDictFiltered('driveline_type', this.filters);
            this.seller_typeList = await this.getDataFromIaaIDictFiltered('seller_type', this.filters);
            this.titleList = await this.getDataFromIaaIDictFiltered('title', this.filters);
            this.engineTypesList = await this.getDataFromIaaIDictFiltered('engine', this.filters);
            break;

          case 'driveline_type':
            this.makeList = await this.getDataFromIaaIDictFiltered('make', this.filters);
            this.modelList = await this.getDataFromIaaIDictFiltered('model', this.filters);
            this.fuel_typeList = await this.getDataFromIaaIDictFiltered('fuel_type', this.filters);
            this.seller_typeList = await this.getDataFromIaaIDictFiltered('seller_type', this.filters);
            this.titleList = await this.getDataFromIaaIDictFiltered('title', this.filters);
            this.engineTypesList = await this.getDataFromIaaIDictFiltered('engine', this.filters);
            break;
          case 'seller_type':
            this.makeList = await this.getDataFromIaaIDictFiltered('make', this.filters);
            this.modelList = await this.getDataFromIaaIDictFiltered('model', this.filters);
            this.driveline_typeList = await this.getDataFromIaaIDictFiltered('driveline_type', this.filters);
            this.fuel_typeList = await this.getDataFromIaaIDictFiltered('fuel_type', this.filters);
            this.titleList = await this.getDataFromIaaIDictFiltered('title', this.filters);
            this.engineTypesList = await this.getDataFromIaaIDictFiltered('engine', this.filters);
            break;
          case 'title':
            this.makeList = await this.getDataFromIaaIDictFiltered('make', this.filters);
            this.seller_typeList = await this.getDataFromIaaIDictFiltered('seller_type', this.filters);
            this.modelList = await this.getDataFromIaaIDictFiltered('model', this.filters);
            this.driveline_typeList = await this.getDataFromIaaIDictFiltered('driveline_type', this.filters);
            this.fuel_typeList = await this.getDataFromIaaIDictFiltered('fuel_type', this.filters);
            this.engineTypesList = await this.getDataFromIaaIDictFiltered('engine', this.filters);
            break;
        }
      }
    }
  }
  async getDataFromCopartDictFiltered(fieldName, filters) {
    const dataArray = [];
    const params = { field: fieldName, filters: filters };
    const response = await API.getDataFromCopartDictFiltered(params);
    for (const fields of response.dict) {
      if(fieldName === 'vehicle_type'){
        dataArray.push(fields);
      }else{
        dataArray.push(fields[fieldName]);
      }
    }
    return dataArray.sort();
  }
  async getDataFromIaaIDictFiltered(fieldName, filters) {
    const dataArray = [];
    const params = { field: fieldName, filters: filters };
    const response = await API.getDataFromIaaIDictFiltered(params);
    for (const fields of response.dict) {
      dataArray.push(fields[fieldName]);
    }
    return dataArray.sort();
  }

  async getVehicleType(value) {
    const fieldName = 'vehicle_type';
    await this.getAllVehicleFields(fieldName, value);
  }
  async getVehicleModelDetail(value) {
    const fieldName = 'model_detail';
    await this.getAllVehicleFields(fieldName, value);
  }
  async getVehicleMake(value) {
    const fieldName = 'make';
    await this.getAllVehicleFields(fieldName, value);
  }
  async getVehicleModel(value) {
    const fieldName = 'model';
    await this.getAllVehicleFields(fieldName, value);
  }
  async getVehicleFuelType(value) {
    const fieldName = 'fuel_type';
    await this.getAllVehicleFields(fieldName, value);
  }
  async getVehicleDriveType(value) {
    const fieldName = 'drive';
    await this.getAllVehicleFields(fieldName, value);
  }
  async getVehicleDrivelineType(value) {
    const fieldName = 'driveline_type';
    await this.getAllVehicleFields(fieldName, value);
  }
  async getVehicleSellerType(value) {
    const fieldName = 'seller_type';
    await this.getAllVehicleFields(fieldName, value);
  }
  async getVehicleTitle(value) {
    const fieldName = 'title';
    await this.getAllVehicleFields(fieldName, value);
  }
  async getVehicleSaleTitleType(value) {
    const fieldName = 'sale_title_type';
    await this.getAllVehicleFields(fieldName, value);
  }
  async getVehicleSaleStatus(value) {
    const fieldName = 'sale_status';
    await this.getAllVehicleFields(fieldName, value);
  }

  async getAllVehicleFields(fieldName = null, value = null) {
    if (fieldName !== null && fieldName.length && value !== null) {
      if (value) {
        this.filters[fieldName] = value;
      } else {
        delete this.filters[fieldName];
        if(this.type === 'copart'){
          switch (fieldName) {
            case 'vehicle_type':
              this.vehicle_typeList = await this.getDataFromCopartDictFiltered(fieldName, this.filters);
              break;
            case 'make':
              this.makeList = await this.getDataFromCopartDictFiltered(fieldName, this.filters);
              break;
            case 'model_detail':
              this.model_detailList = await this.getDataFromCopartDictFiltered(fieldName, this.filters);
              break;
            case 'drive':
              this.driveList = await this.getDataFromCopartDictFiltered(fieldName, this.filters);
              break;
            case 'sale_status':
              this.sale_statusList = await this.getDataFromCopartDictFiltered(fieldName, this.filters);
              break;
            case 'sale_title_type':
              this.sale_title_typeList = await this.getDataFromCopartDictFiltered(fieldName, this.filters);
              break;
            case 'fuel_type':
              this.fuel_typeList = await this.getDataFromCopartDictFiltered(fieldName, this.filters);
              break;
          }
        }else{
          switch (fieldName) {
            case 'make':
              this.makeList = await this.getDataFromIaaIDictFiltered(fieldName, this.filters);
              break;
            case 'model':
              this.modelList = await this.getDataFromIaaIDictFiltered(fieldName, this.filters);
              break;
            case 'driveline_type':
              this.driveline_typeList = await this.getDataFromIaaIDictFiltered(fieldName, this.filters);
              break;
            case 'seller_type':
              this.seller_typeList = await this.getDataFromIaaIDictFiltered(fieldName, this.filters);
              break;
            case 'title':
              this.titleList = await this.getDataFromIaaIDictFiltered(fieldName, this.filters);
              break;
            case 'fuel_type':
              this.fuel_typeList = await this.getDataFromIaaIDictFiltered(fieldName, this.filters);
              break;
          }
        }
      }
      if(fieldName === 'engine' && !value.length){
        this.carSearchForm[fieldName] = [];
      }else{
        this.carSearchForm[fieldName] = value;
      }
    }
    this.getFilteredDataToFields(fieldName);
  }
  getAuctionsByInputs(dataName, value){
    this.carSearchForm[dataName] = value;
    if(value != this.carSearchForm[dataName]){
      if (value) {
        this.filters[dataName] = value
      } else {
        delete this.filters[dataName];
      }
      this.carSearchForm[dataName] = value;
    }
  }
  async getYearGte(value) {
    this.getAuctionsByInputs('year__gte', value);
  }
  async getYearLte(value) {
    this.getAuctionsByInputs('year__lte', value);
  }

  async getOdometerGte(value) {
    this.getAuctionsByInputs('odometer__gte', value);
  }
  async getOdometerLte(value) {
    this.getAuctionsByInputs('odometer__lte', value);
  }

  async getCylindersGte(value) {
    if(this.type === 'copart'){
      this.getAuctionsByInputs('cylinders__gte', value);
    }else{
      this.getAuctionsByInputs('cylinder__gte', value);
    }
  }
  async getCylindersLte(value) {
    if(this.type === 'copart'){
      this.getAuctionsByInputs('cylinders__lte', value);
    }else{
      this.getAuctionsByInputs('cylinder__lte', value);
    }
  }

  async getHigihBidGte(value) {
    this.getAuctionsByInputs('high_bid__gte', value);
  }
  async getHigihBidLte(value) {
    this.getAuctionsByInputs('high_bid__lte', value);
  }

  async getCurrentBidGte(value) {
    this.getAuctionsByInputs('current_bid__gte', value);
  }
  async getCurrentBidLte(value) {
    this.getAuctionsByInputs('current_bid__lte', value);
  }
  @Emit("update-auctions")
  async updateAuctions(dataName: any = false, dataValue: any = false, activeFilter: any = true) {
    this.routeQuery = JSON.stringify(this.$route.query);
    if (dataName && dataValue && !activeFilter) {
      // change value from datalistinput
      const query = Object.assign({}, this.$route.query);
      query[dataName] = dataValue;
      if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
        await this.$router.replace({query});
      }
    } else if (dataName) {
      // change value from activefilters
      if(dataName === 'engine'){
        this.carSearchForm[dataName] = [];
      }else{
        this.carSearchForm[dataName] = '';
      }
      const query = Object.assign({}, this.$route.query);
      delete query[dataName];
      if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
        await this.$router.replace({query});
      }
      await this.getAllVehicleFields(dataName, '');
    }
    this.routeQuery = JSON.stringify(this.$route.query);
    return true;
  }
  clearForm(){
    const query = Object.assign({}, this.$route.query);
    Object.keys(this.carSearchForm).forEach(key => {
      if(key === 'engine'){
        this.carSearchForm[key] = [];
      }else{
        delete this.carSearchForm[key];
      }
    });
  }
  @Emit("update-auctions")
  disableFilters(){
    this.clearForm();
    this.routeQuery = JSON.stringify(this.$route.query);
    return true;
  }
  setDefaultValues(){
    // clear form
    for (const [key, value] of Object.entries(this.carSearchForm)){
      if(key === 'engine'){
        this.carSearchForm[key] = [];
      }else{
        this.carSearchForm[key] = '';
      }
    }
    // set values from parameters
    for (const [key, value] of Object.entries(this.carSearchForm)){
      if(Object.prototype.hasOwnProperty.call(this.$route.query, key)){
        this.carSearchForm[key] = this.$route.query[key];
      }
    }
    this.isDefaultSet = true;
  }

  mounted(){
    this.setDefaultValues();
    for (const [key, value] of Object.entries(this.carSearchForm)){
      if(Object.prototype.hasOwnProperty.call(this.$route.query, key)){
        this.filters[key] = value;
      }
    }
  }
}
