
















































































































































import {Component, Vue, Prop} from 'vue-property-decorator';
import {required, between} from 'vuelidate/lib/validators'
import { mixins } from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import FormSelect from "@/components/dummy/FormSelect.vue";

@Component({
  components: {
    FormSelect
  },
  mixins: [validationMixin],
  validations: {
    calculatorForm: {
      generalMonthlyDistance: {
        required,
        between: between(0, 999999)
      },

      electricPurchaseVehicle: {
        required,
        between: between(0, 999999)
      },
      electricAnnual: {
        required,
        between: between(0, 99999)
      },
      electricNewBatteries: {
        required,
        between: between(0, 99999)
      },
      electricNewBatteriesMonths: {
        required,
        between: between(0, 1000)
      },
      electricKWhPrice: {
        required,
        between: between(0, 10)
      },
      electricAvConsumptionPer100: {
        required,
        between: between(0, 1000)
      },

      dieselPurchaseVehicle: {
        required,
        between: between(0, 999999)
      },
      dieselAnnual: {
        required,
        between: between(0, 99999)
      },
      dieselLiterFuel: {
        required,
        between: between(0, 100)
      },
      dieselAvConsumptionPer100: {
        required,
        between: between(0, 100)
      },
    },
  },
})
export default class ElectricVehicleProfitabilityCalculator extends mixins(Vue, validationMixin){
  @Prop({ type: String, required: false, default: "" }) title;
  private numberOfYears = 10;
  private generalMonthlyDistanceCost = 1500;

  private electricPurchaseVehicleCost = 100000;
  private electricAnnualCost = 1500;
  private electricNewBatteriesCost = 30000;
  private electricNewBatteriesMonthsCost = 96;
  private electricKWhPriceCost = 0.4;
  private electricAvConsumptionPer100Cost = 18;

  private dieselPurchaseVehicleCost = 60000;
  private dieselAnnualCost = 2500;
  private dieselLiterFuelCost = 6;
  private dieselAvConsumptionPer100Cost = 6;

  calculatorForm = {
    generalMonthlyDistance: this.generalMonthlyDistanceCost,

    electricPurchaseVehicle: this.electricPurchaseVehicleCost,
    electricAnnual: this.electricAnnualCost,
    electricNewBatteries: this.electricNewBatteriesCost,
    electricNewBatteriesMonths: this.electricNewBatteriesMonthsCost,
    electricKWhPrice: this.electricKWhPriceCost,
    electricAvConsumptionPer100: this.electricAvConsumptionPer100Cost,

    dieselPurchaseVehicle: this.dieselPurchaseVehicleCost,
    dieselAnnual: this.dieselAnnualCost,
    dieselLiterFuel: this.dieselLiterFuelCost,
    dieselAvConsumptionPer100: this.dieselAvConsumptionPer100Cost,
  }




  // set default values
  private defaultDoorTransport = 2000;
  private defaultDocumentTranslations = 400;
  private defaultExpertOpinion = 500;
  private defaultExciseDeclaration = 150;
  private defaultFixedAuctionHouseCommission = 250;
  private defaultBroker = 250;
  private defaultCustomsAgency = 480;
  // set default values

  // set default values to final variables
  private doorTransport = this.defaultDoorTransport;
  private documentTranslations = this.defaultDocumentTranslations;
  private expertOpinion = this.defaultExpertOpinion;
  private exciseDeclaration = this.defaultExciseDeclaration;
  private fixedAuctionHouseCommission = this.defaultFixedAuctionHouseCommission;
  private broker = this.defaultBroker;
  private customsAgency = this.defaultCustomsAgency;
  // set default values to final variables

  calculationResult(){
    console.log('calculationResult');
    const results = []
    let mileageResults;
    let electricResults;
    let dieselResults;
    let generalMonthlyDistanceCost = 0;

    let electricPurchaseVehicleCost = 0;
    let electricAnnualCost = 0;
    let electricNewBatteriesCost = 0;
    let electricNewBatteriesMonthsCost = 0;
    let electricKWhPriceCost = 0;
    let electricAvConsumptionPer100Cost = 0;

    let dieselPurchaseVehicleCost = 0;
    let dieselAnnualCost = 0;
    let dieselLiterFuelCost = 0;
    let dieselAvConsumptionPer100Cost = 0;

    if(this.$v.calculatorForm.generalMonthlyDistance.$model){
      if(this.$v.calculatorForm.generalMonthlyDistance.$model > 0){
        console.log('calculatorForm.generalMonthlyDistance.$model');
        generalMonthlyDistanceCost = this.$v.calculatorForm.generalMonthlyDistance.$model;

        if(this.$v.calculatorForm.electricPurchaseVehicle.$model){
          if(this.$v.calculatorForm.electricPurchaseVehicle.$model > 0) {
            electricPurchaseVehicleCost = this.$v.calculatorForm.electricPurchaseVehicle.$model;
          }
        }

        if(this.$v.calculatorForm.electricAnnual.$model){
          if(this.$v.calculatorForm.electricAnnual.$model > 0) {
            electricAnnualCost = this.$v.calculatorForm.electricAnnual.$model;
          }
        }

        if(this.$v.calculatorForm.electricNewBatteries.$model){
          if(this.$v.calculatorForm.electricNewBatteries.$model > 0) {
            electricNewBatteriesCost = this.$v.calculatorForm.electricNewBatteries.$model;
          }
        }

        if(this.$v.calculatorForm.electricNewBatteriesMonths.$model){
          if(this.$v.calculatorForm.electricNewBatteriesMonths.$model > 0) {
            electricNewBatteriesMonthsCost = this.$v.calculatorForm.electricNewBatteriesMonths.$model;
          }
        }

        if(this.$v.calculatorForm.electricKWhPrice.$model){
          if(this.$v.calculatorForm.electricKWhPrice.$model > 0) {
            electricKWhPriceCost = this.$v.calculatorForm.electricKWhPrice.$model;
          }
        }

        if(this.$v.calculatorForm.electricAvConsumptionPer100.$model){
          if(this.$v.calculatorForm.electricAvConsumptionPer100.$model > 0) {
            electricAvConsumptionPer100Cost = this.$v.calculatorForm.electricAvConsumptionPer100.$model;
          }
        }

        console.log('kosztyyy');
        console.log(electricPurchaseVehicleCost);
        console.log(electricAnnualCost);
        console.log(electricNewBatteriesCost);
        console.log(electricNewBatteriesMonthsCost);
        console.log(electricKWhPriceCost);
        console.log(electricAvConsumptionPer100Cost);


        if(this.$v.calculatorForm.dieselPurchaseVehicle.$model){
          if(this.$v.calculatorForm.dieselPurchaseVehicle.$model > 0) {
            dieselPurchaseVehicleCost = this.$v.calculatorForm.dieselPurchaseVehicle.$model;
          }
        }

        if(this.$v.calculatorForm.dieselAnnual.$model){
          if(this.$v.calculatorForm.dieselAnnual.$model > 0) {
            dieselAnnualCost = this.$v.calculatorForm.dieselAnnual.$model;
          }
        }

        if(this.$v.calculatorForm.dieselLiterFuel.$model){
          if(this.$v.calculatorForm.dieselLiterFuel.$model > 0) {
            dieselLiterFuelCost = this.$v.calculatorForm.dieselLiterFuel.$model;
          }
        }

        if(this.$v.calculatorForm.dieselAvConsumptionPer100.$model){
          if(this.$v.calculatorForm.dieselAvConsumptionPer100.$model > 0) {
            dieselAvConsumptionPer100Cost = this.$v.calculatorForm.dieselAvConsumptionPer100.$model;
          }
        }

        mileageResults = this.calcMileage(generalMonthlyDistanceCost);
        console.log('mileageResults');
        console.log(mileageResults);

        // do przemnozenia przez rok
        electricResults = this.calcElectricResults(electricPurchaseVehicleCost, electricAnnualCost, generalMonthlyDistanceCost, electricKWhPriceCost, electricAvConsumptionPer100Cost, electricNewBatteriesCost, electricNewBatteriesMonthsCost);
        console.log('electricResults');
        console.log(electricResults);
        dieselResults = this.calcDieselResults(dieselPurchaseVehicleCost, dieselAnnualCost, generalMonthlyDistanceCost, dieselLiterFuelCost, dieselAvConsumptionPer100Cost);
        console.log('dieselResults');
        console.log(dieselResults);

        for (let i = 1; i <= 10; i++){
          const tableRow = [];
          tableRow.push(mileageResults[i - 1], electricResults[i - 1], dieselResults[i - 1]);
          results.push(tableRow)
        }
        return results;
      }
      return [];
    }
  }

  calcElectricResults(electricPurchaseVehicleCost, electricAnnualCost, generalMonthlyDistanceCost, electricKWhPriceCost, electricAvConsumptionPer100Cost, electricNewBatteriesCost, electricNewBatteriesMonthsCost){
    const results = [];
    const initialCost = electricPurchaseVehicleCost;
    let annualResult = initialCost;
    const yearsToReplaceBatteries = Math.floor(electricNewBatteriesMonthsCost / 12);
    const annualEnergyConsumption = ((generalMonthlyDistanceCost * 12) / 100) * electricKWhPriceCost * electricAvConsumptionPer100Cost;

    for (let i = 1; i <= this.numberOfYears; i++){

      console.log('rok');
      console.log(i);

      console.log('koszty stale:');
      console.log(initialCost);
      console.log('ksozty roczne:');
      console.log(annualResult);

      let batteryCost = 0;
      if(!(i % yearsToReplaceBatteries)){
        console.log('wymiana aku');
        console.log(yearsToReplaceBatteries);
        console.log(i);
        batteryCost = electricNewBatteriesCost;
      }
      console.log('zucyie energii na 1 rok:');
      console.log(annualEnergyConsumption);
      console.log('koszt baterii w tym roku:');
      console.log(batteryCost);
      console.log('koszt z zeszlego roku:');
      console.log(annualResult);
      annualResult += Math.ceil(electricAnnualCost + annualEnergyConsumption + batteryCost);
      console.log('aktualny koszt:');
      console.log(annualResult);
      results.push(annualResult);
    }
    return results;
  }

  calcDieselResults(dieselPurchaseVehicleCost, dieselAnnualCost, generalMonthlyDistanceCost, dieselLiterFuelCost, dieselAvConsumptionPer100Cost){
    const results = [];
    const initialCost = dieselPurchaseVehicleCost;
    let annualResult = initialCost;
    const annualConsumption = ((generalMonthlyDistanceCost * 12) / 100) * dieselLiterFuelCost * dieselAvConsumptionPer100Cost;

    for (let i = 1; i <= this.numberOfYears; i++){

      console.log('rok');
      console.log(i);

      console.log('ksozty roczne:');
      console.log(annualResult);

      console.log('zucyie paliwa na 1 rok:');
      console.log(annualConsumption);
      console.log('koszt z zeszlego roku:');
      console.log(annualResult);
      annualResult += Math.ceil(annualConsumption + dieselAnnualCost);
      console.log('aktualny koszt:');
      console.log(annualResult);
      results.push(annualResult);
    }
    return results;
  }

  calcMileage(generalMonthlyDistanceCost){
    const mileageArray = [];
    const annualMileage = generalMonthlyDistanceCost * 12;
    for(let i = 1; i <= this.numberOfYears; i++){
      mileageArray.push(annualMileage * i);
    }
    return mileageArray;
  }
  calculatorSubmit(){
    this.$v.$touch()
    if (this.$v.$invalid) {
      return false;
    } else {
      return this.calculatorForm;
    }
  }

  mounted(){
    //
  }
}
