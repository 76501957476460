











import { Component, Vue } from 'vue-property-decorator';
import './helpers';
import Footer from '@/components/dummy/Footer.vue';
import Header from '@/components/dummy/Header.vue';
import Vuelidate from 'vuelidate';
import Modal from "@/components/spec/Modal.vue";
import ModalAction from "@/components/spec/ModalAction.vue";
Vue.use(Vuelidate)

@Component({
  components: {
    Footer,
    Header,
    Modal,
    ModalAction,
  },
})
export default class App extends Vue {
  mounted(){
    console.log('1.0c');
  }
}
