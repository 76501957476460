


















































































































































































import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import * as consts from '../../consts';
import * as API from "@/store/api";
import Countdown from "@/components/dummy/Countdown.vue";
import Odometer from "@/components/dummy/Odometer.vue";
@Component({
  components: {
    Countdown,
    Odometer
  },
})
export default class AuctionTile extends Vue {
  @Prop() auctionData;
  @Prop({ type: String, required: false, default: 'copart' }) type;
  @Prop({ required: true, default: '' }) userId;

  @Watch('auctionData')
  auctionDataChange() {
    this.setData();
  }
  imgDefault = consts.auctionImageDefaultSource;
  thumbnail = this.imgDefault;
  countDown = null
  days = 0;
  hours = 0;
  minutes = 0;
  seconds = 0;
  isTimeLeft = true;
  countDownDate;
  auctionPath = "";
  private isWatched = false;
  setCountDownCopart () {
    this.countDownDate = new Date(this.auctionData.sale_date);
    this.countDownDate = this.countDownDate.getTime();
    if(this.isTimeLeft === true){
      this.countDown = setInterval(() => {
        const currentDate = new Date().getTime();
        const timeLeft = this.countDownDate - currentDate;
        if(timeLeft <= 0){
          this.isTimeLeft = false;
          clearInterval(this.countDown);
        }
        this.days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
        this.hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
      }, 1000)
    }
  }

  async setThumbnailCopart(){
    const last  = '/' + this.auctionData.image_url.split("/").pop();
    const galleryJson = { "param" : last };

    const response = await API.getAuctionImages(galleryJson);
    if(typeof response === "object"){
      if(typeof response.lotImages !== 'undefined'){
        const linkObject = JSON.parse(JSON.stringify(response.lotImages[0]["link"]));
        for (const linkEl of Object.keys(linkObject)) {
          let isHdImage = null;
          let isThumbNail = null;
          let tmpUrl = null;
          isHdImage = linkObject[linkEl]["isHdImage"];
          isThumbNail = linkObject[linkEl]["isThumbNail"];
          tmpUrl = linkObject[linkEl]["url"];
          if(!isThumbNail && isHdImage){
            this.thumbnail = tmpUrl;
            break;
          }
          if(!isThumbNail && !isHdImage){
            this.thumbnail = tmpUrl;
            break;
          }
          if(isThumbNail && !isHdImage){
            this.thumbnail = tmpUrl;
            break;
          }
        }
      }else{
        this.thumbnail = 'http://' + this.auctionData.image_thumbnail;
      }
    }else{
      console.log('BLAD  ' + response);
      this.thumbnail = 'http://' + this.auctionData.image_thumbnail;
    }
  }
  setThumbnailIaai(){
    if(this.auctionData.img_src.length){
      this.thumbnail = this.auctionData.img_src;
    }
  }
  async addUserStarred(userId, vin, auctionType){
    await API.addUserStarred(userId, vin, auctionType);
  }
  addStarred(){
    const auctionType = this.type === 'copart'? 1 : 2;
    if(auctionType == 1){
      this.addUserStarred(this.userId, this.auctionData.VIN, auctionType);
    }else if(auctionType == 2){
      this.addUserStarred(this.userId, this.auctionData.vin, auctionType);
    }
    this.isWatched = !this.isWatched;
  }
  async deleteUserStarred(userId, vin){
    await API.deleteUserStarred(userId, vin);
  }
  deleteStarred(){
    this.deleteUserStarred(this.userId, this.auctionData.vin);
    this.isWatched = !this.isWatched;
  }
  handleUserStarred(){
    if(this.isWatched){
      this.deleteStarred();
    }else{
      this.addStarred();
    }
  }
  checkStarredStatus(){
    if(Object.prototype.hasOwnProperty.call(this.auctionData, 'is_watched')){
      if(this.auctionData.is_watched === 1){
        this.isWatched = true;
      }
    }else{
      this.isWatched = false;
    }
  }
  setData(){
    if(this.type === 'copart'){
      this.setCountDownCopart();
      this.setThumbnailCopart();
      this.auctionPath = '/lot?id='+ this.auctionData.lot_number;
    }else{
      this.auctionPath = '/stock?id=' + this.auctionData.stock_no;
      this.setThumbnailIaai();
    }
    this.checkStarredStatus();
  }
  mounted(){
    this.setData();
  }
}
