











































































import {Component, Vue, Prop} from 'vue-property-decorator';
import {required, between} from 'vuelidate/lib/validators'
import { mixins } from 'vue-class-component';
import { validationMixin } from 'vuelidate';
import FormSelect from "@/components/dummy/FormSelect.vue";

@Component({
  components: {
    FormSelect
  },
  mixins: [validationMixin],
  validations: {
    calculatorForm: {
      valueOfUndamaged: {
        required,
        between: between(0, 99999999)
      },
      amountOfDamage: {
        required,
        between: between(0, 100)
      },
      taxBaseSelected: {
      },
    },
  },
})
export default class ExciseTaxCostCalculator extends mixins(Vue, validationMixin){
  private calcCoefficient = 1.23;
  private taxBase = [
    { value: '0', text: '0%' },
    { value: '3.1', text: '3.1%' },
    { value: '18.6', text: '18.6%' },
  ];
  calculatorForm = {
    valueOfUndamaged: 0,
    amountOfDamage: 0,
    taxBaseSelected: parseFloat(this.taxBase[0].value),
  }
  taxBaseChange(value){
    this.calculatorForm.taxBaseSelected = parseFloat(value);
    this.calculatorSubmit();
  }
  calculationResult(){
    if(this.$v.calculatorForm.valueOfUndamaged.$model && this.$v.calculatorForm.taxBaseSelected.$model){
      if(this.$v.calculatorForm.valueOfUndamaged.$model > 0 && this.$v.calculatorForm.taxBaseSelected.$model > 0){
        let valueOfDamage: any = (this.$v.calculatorForm.valueOfUndamaged.$model * (this.$v.calculatorForm.amountOfDamage.$model / 100)).toFixed(2);
        valueOfDamage = parseFloat(valueOfDamage);
        let valueOfDamaged: any = (this.$v.calculatorForm.valueOfUndamaged.$model - valueOfDamage).toFixed(2);
        valueOfDamaged = parseFloat(valueOfDamaged);
        const taxBaseSelectedVal = (1 + (this.$v.calculatorForm.taxBaseSelected.$model / 100));
        const valueOfDamagedWithoutVAT = valueOfDamaged / this.calcCoefficient;
        let result = valueOfDamagedWithoutVAT / taxBaseSelectedVal;
        result *= (this.$v.calculatorForm.taxBaseSelected.$model / 100);
        result = parseFloat(result.toFixed(0));
        return result.toString();
      }
    }else{
      return 0;
    }
  }
  calculatorSubmit(){
    this.$v.$touch()
    if (this.$v.$invalid) {
      return false;
    } else {
      return this.calculatorForm;
    }
  }
}
