






























































































































import {Component, Vue, Emit, Ref} from 'vue-property-decorator';
import DataListInput from '@/components/dummy/DataListInput.vue';
import * as API from '@/store/api';
@Component({
  components: {
    DataListInput,
  },
})
export default class AuctionSearchLite extends Vue {
  @Ref() readonly InputDataListMake: DataListInput
  @Ref() readonly InputDataListModelDetail: DataListInput
  @Ref() readonly InputDataListModel: DataListInput
  @Ref() readonly InputDataListYearFrom: DataListInput
  @Ref() readonly InputDataListYearTo: DataListInput

  auctionType = 1;

  carSearchForm = {
    vehicle_type: '',
    make: '',
    model_detail: '',
    year__gte: '',
    year__lte: '',
  };
  carSearchFormIaai = {
    make: '',
    model: '',
    year__gte: '',
    year__lte: '',
  };

  vehicle_typeLabel = 'TYP';
  makeLabel = 'MARKA POJAZDU';
  model_detailLabel = 'MODEL POJAZDU';
  year__gteLabel = 'ROCZNIK OD';
  year__lteLabel = 'ROCZNIK DO';

  fieldNames = ['vehicle_type', 'make', 'model_detail', 'year'];
  fieldNamesIaai = ['model', 'year'];
  vehicle_typeList = [];
  makeList = [];
  model_detailList = [];
  model_List = [];
  year__gteList = [];
  year__lteList = [];
  filters = {};

  goToAuctionsWithData(event) {
    event.preventDefault();
    window.scrollTo(0, 0);
    const routerQueryJSON = {};

    if (this.auctionType === 1) {
      for (const [key, value] of Object.entries(this.carSearchForm)) {
        if (value.length) {
          routerQueryJSON[key] = value;
        }
      }
      this.$router.push({
        name: 'auctionsCopart',
        query: routerQueryJSON,
      });
    } else if (this.auctionType === 2) {
      for (const [key, value] of Object.entries(this.carSearchFormIaai)) {
        if (value.length) {
          routerQueryJSON[key] = value;
        }
      }
      this.$router.push({
        name: 'auctionsIaai',
        query: routerQueryJSON,
      });
    }
  }

  async getVehicleType(value) {
    this.carSearchForm.vehicle_type = value;
    if (value) {
      this.filters['vehicle_type'] = value;
    } else {
      delete this.filters['vehicle_type'];
      this.vehicle_typeList = await this.getDataFromCopartDictFiltered('vehicle_type', this.filters);
    }
    this.makeList = await this.getDataFromCopartDictFiltered('make', this.filters);
    this.model_detailList = await this.getDataFromCopartDictFiltered('model_detail', this.filters);
  }

  selectAuctionType(clickedAuctionType) {
    this.filters = {}
    this.carSearchForm = {
      vehicle_type: '',
      make: '',
      model_detail: '',
      year__gte: '',
      year__lte: '',
    };
    this.carSearchFormIaai = {
      make: '',
      model: '',
      year__gte: '',
      year__lte: '',
    };

    if(clickedAuctionType === 1){
      if(this.InputDataListModel){
        this.InputDataListModel.clearValue();
      }
    }else{
      if(this.InputDataListMake){
        this.InputDataListMake.clearValue();
      }
      if(this.InputDataListModelDetail){
        this.InputDataListModelDetail.clearValue();
      }
    }
    this.InputDataListYearFrom.clearValue();
    this.InputDataListYearTo.clearValue();
    this.auctionType = clickedAuctionType;
  }

  async getVehicleMake(value) {
    if(this.auctionType === 1){
      this.carSearchForm.make = value;
      if (value) {
        this.filters['make'] = value;
      } else {
        delete this.filters['make'];
        this.makeList = await this.getDataFromCopartDictFiltered('make', this.filters);
      }
      this.vehicle_typeList = await this.getDataFromCopartDictFiltered('vehicle_type', this.filters);
      this.model_detailList = await this.getDataFromCopartDictFiltered('model_detail', this.filters);
    }else{


      this.carSearchFormIaai.make = value;
      if (value) {
        this.filters['make'] = value;
      } else {
        delete this.filters['make'];
        this.makeList = await this.getDataFromIaaIDictFiltered('make', this.filters);
      }


    }
  }
  async getVehicleModelDetail(value) {
    this.carSearchForm.model_detail = value;
    if (value) {
      this.filters['model_detail'] = value;
    } else {
      delete this.filters['model_detail'];
      this.model_detailList = await this.getDataFromCopartDictFiltered('model_detail', this.filters);
    }
    this.vehicle_typeList = await this.getDataFromCopartDictFiltered('vehicle_type', this.filters);
    this.makeList = await this.getDataFromCopartDictFiltered('make', this.filters);
  }
  async getVehicleModel(value) {
    this.carSearchFormIaai.model = value;
    if (value) {
      this.filters['model'] = value;
    } else {
      delete this.filters['model'];
      this.model_List = await this.getDataFromIaaIDictFiltered('model', this.filters);
    }
  }
  async getYearFrom(value) {
    if (value) {
      this.filters['year__gte'] = value;
    } else {
      delete this.filters['year__gte'];
      if(this.auctionType === 1){
        this.year__gteList = await this.getDataFromCopartDictFiltered('year', this.filters);
      }else{
        this.year__gteList = await this.getDataFromIaaIDictFiltered('year', this.filters);
      }
    }
    if(this.auctionType === 1){
      this.carSearchForm.year__gte = value;
      this.vehicle_typeList = await this.getDataFromCopartDictFiltered('vehicle_type', this.filters);
      this.makeList = await this.getDataFromCopartDictFiltered('make', this.filters);
      this.model_detailList = await this.getDataFromCopartDictFiltered('model_detail', this.filters);
      this.year__lteList = await this.getDataFromCopartDictFiltered('year', this.filters);
    }else{
      this.carSearchFormIaai.year__gte = value;
      this.model_List = await this.getDataFromIaaIDictFiltered('model', this.filters);
      this.year__lteList = await this.getDataFromIaaIDictFiltered('year', this.filters);
    }
  }
  async getYearTo(value) {
    if (value) {
      this.filters['year__lte'] = value;
    } else {
      delete this.filters['year__lte'];
      if(this.auctionType === 1) {
        this.year__lteList = await this.getDataFromCopartDictFiltered('year', this.filters);
      }else{
        this.year__lteList = await this.getDataFromIaaIDictFiltered('year', this.filters);
      }
    }
    if(this.auctionType === 1){
      this.carSearchForm.year__lte = value;
      this.vehicle_typeList = await this.getDataFromCopartDictFiltered('vehicle_type', this.filters);
      this.makeList = await this.getDataFromCopartDictFiltered('make', this.filters);
      this.model_detailList = await this.getDataFromCopartDictFiltered('model_detail', this.filters);
      this.year__gteList = await this.getDataFromCopartDictFiltered('year', this.filters);
    }else{
      this.carSearchFormIaai.year__lte = value;
      this.model_List = await this.getDataFromIaaIDictFiltered('model', this.filters);
      this.year__gteList = await this.getDataFromIaaIDictFiltered('year', this.filters);
    }
  }

  async getDataFromCopartDictFiltered(fieldName, filters) {
    const dataArray = [];
    const params = { field: fieldName, filters: filters };
    const response = await API.getDataFromCopartDictFiltered(params);
    for (const fields of response.dict) {
      if(fieldName === 'vehicle_type'){
        dataArray.push(fields);
      }else{
        dataArray.push(fields[fieldName]);
      }
    }
    return dataArray.sort();
  }
  async getDataFromIaaIDictFiltered(fieldName, filters) {
    const dataArray = [];
    const params = { field: fieldName, filters: filters };
    const response = await API.getDataFromIaaIDictFiltered(params);
    for (const fields of response.dict) {
      dataArray.push(fields[fieldName]);
    }
    return dataArray.sort();
  }
  async getDataFromIaaiDict(fieldName) {
    const dataArray = [];
    const response = await API.getDataFromIaaiDict(fieldName);
    if (typeof response === 'object') {
      for (const fields of Object.values(response.dict)) {
        dataArray.push(fields[fieldName]);
      }
      return dataArray.sort();
    } else {
      console.log('BLAD  ' + response);
      return [];
    }
  }

  mounted() {
    const dictPromisesArr = [];
    const dictPromisesArrIaai = [];
    for (const fieldName of Object.values(this.fieldNames)) {
      dictPromisesArr.push(this.getDataFromCopartDictFiltered(fieldName, this.filters));
    }
    for (const fieldName of Object.values(this.fieldNamesIaai)) {
      dictPromisesArrIaai.push(this.getDataFromIaaiDict(fieldName));
    }
    Promise.all(dictPromisesArr).then(values => {
      this.vehicle_typeList = values[0];
      this.makeList = values[1];
      this.model_detailList = values[2];
      this.year__gteList = values[3];
      this.year__lteList = values[3];
      let tmp = [...values[3]];
      tmp = tmp.reverse();
      this.year__lteList = tmp;
    });
    Promise.all(dictPromisesArrIaai).then(values => {
      this.model_List = values[0];
      this.year__gteList = values[1];
      this.year__lteList = values[1];
      let tmp = [...values[1]];
      tmp = tmp.reverse();
      this.year__lteList = tmp;
    });
  }
}
